<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-form
          ref="propectingForm"
          v-model="isValidForm"
          lazy-validation
        >
          <v-card
            style="
              height: 100%;
            "
          >
            <v-toolbar
              dark
              color="primary"
              style="
                height: 64px;
                position: fixed;
                width: 100%;
              "
            >
              <v-btn
                icon
                dark
                @click="() => close()"
                :loading="is_loading"
                :disabled="is_loading"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Prospecção</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  @click="() => save()"
                  :loading="is_loading"
                  :disabled="is_loading"
                >
                  Salvar
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div
              style="
                padding-top: 64px;
              "
            >
              <v-row>
                <v-col cols="12">
                  <div
                    style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "
                  ><span
                    style="
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >Dados do Cliente</span></div>
                </v-col>
                <v-col cols="2">
                  <v-radio-group 
                    v-model="radioDocument"
                    :disabled="is_new_project"
                  >
                    <v-radio
                      v-for="option in radioDocumentOptions"
                      :key="option"
                      :label="option"
                      :value="option"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="prospecting.document"
                        :label="`Digite o ${radioDocument} do Cliente`"
                        required
                        :rules="radioDocument == 'CPF' ? ruleCPF(true) : ruleCNPJ(true)"
                        :loading="is_loading"
                        :disabled="is_loading || is_new_project"
                        v-mask="radioDocument == 'CPF' ? '###.###.###-##' : '##.###.###/####-##'"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.name"
                      label="Nome do Cliente"
                      :counter="64"
                      required
                      :rules="ruleString(true, 64)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.email"
                      label="Email do Cliente"
                      :counter="64"
                      required
                      :rules="ruleEmail(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.phone"
                      label="Telefone do Cliente"
                      required
                      :rules="ruleRequired()"
                      :loading="is_loading"
                      :disabled="is_loading"
                      v-mask="'(##) # ####-####'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12">
                  <div
                    style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "
                  ><span
                    style="
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >Endereço</span></div>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.zip_code"
                      label="CEP"
                      required
                      :rules="ruleCEP(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                      v-mask="'##.###-###'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.address"
                      label="Logradouro"
                      required
                      :rules="ruleString(true, 128)"
                      :counter="128"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.house_number"
                      label="Número"
                      required
                      :counter="16"
                      :rules="ruleString(true, 16)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.address_complement"
                      label="Complemento"
                      required
                      :counter="64"
                      :rules="ruleString(true, 64)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.district"
                      label="Bairro"
                      required
                      :counter="64"
                      :rules="ruleString(true, 64)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.city"
                      label="Cidade"
                      required
                      :counter="64"
                      :rules="ruleString(true, 64)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                      v-model="prospecting.uf"
                      label="UF"
                      required
                      :items="state_acronyms"
                      :rules="ruleRequired()"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div
                    style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "
                  ><span
                    style="
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >Dados do Reponsável</span></div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.responsible_name"
                      label="Nome do Responsável"
                      required
                      :counter="64"
                      :rules="ruleString(true, 64)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.responsible_email"
                      label="Email do Responsável"
                      required
                      :counter="64"
                      :rules="ruleEmail(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.responsible_document"
                      label="CPF do Responsável"
                      required
                      :rules="ruleCPF(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                      v-mask="'###.###.###-##'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="prospecting.responsible_phone"
                      label="Telefone do Responsável"
                      required
                      :rules="ruleRequired()"
                      :loading="is_loading"
                      :disabled="is_loading"
                      v-mask="'(##) # ####-####'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div
                    style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "
                  ><span
                    style="
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >Dados Gerais</span></div>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.registration"
                      label="Matrícula"
                      required
                      :counter="32"
                      :rules="ruleString(true, 32)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.minimum_rate"
                      label="Taxa Mínima"
                      type="Number"
                      required
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.financial_average_per_unit"
                      label="Média Financeira por Unidade (R$)"
                      type="Number"
                      prefix="R$"
                      required
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.number_units"
                      label="Quantidade de Unidade"
                      required
                      type="Number"
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.number_towers"
                      label="Quantidade de Torres"
                      required
                      type="Number"
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.number_floors"
                      label="Número de Pavimentos"
                      required
                      type="Number"
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.unit_by_floor"
                      label="Unidades por Andar"
                      required
                      type="Number"
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="prospecting.wcs_by_unit"
                      label="Banheiros por Unidade"
                      required
                      type="Number"
                      :rules="ruleIntPositive(true)"
                      :loading="is_loading"
                      :disabled="is_loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
</template>
  
<script>
import Prospecting from "./Prospecting.js";
export default Prospecting;
</script>
  
<style scoped>

</style>