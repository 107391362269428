import { mapActions, mapGetters } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

import ProjectsService from "@/services/server/ProjectsService.js";
import LeadsService from "@/services/server/LeadsService.js";
import UsersService from "@/services/server/UsersService";

import Propecting from "@/components/Projects/Prospecting/Prospecting.vue"

export default {
	name: "Projetos",

	components: {
		Propecting,
	},

	data: () => ({
		dialog: false,
		is_created: false,
		is_loading: true,
		isValidForm: false,
		form: {
			project_name: null,
			lead_document: null,
			id_franchisee: null,
		},
		type_lead_documents: [
			'CPF',
			'CNPJ',
			'Matrícula',
		],
		type_lead_documents_masks: {
			'CPF': "###.###.###-##",
			'CNPJ': "##.###.###/####-##",
			'Matrícula': null,
		},
		type_lead_document_selected: 'CPF',
		headers: [
			{ text: 'Nome do Projeto', value: "project_name", },
			{ text: 'Cliente', value: 'lead_name', },
			{ text: 'Documento (CPF/CNPJ)', value: 'lead_document', },
			{ text: 'Data de Criação', value: 'created_at', },
			{ text: 'Ações', value: 'actions', },
		],
        items: [],
		searchField: '',
		searchOptions: [
			'Nome do Projeto',
			'Cliente',
			'Documento (CPF/CNPJ)'
		],
		searchOptionSelected: 'Nome do Projeto',
		franchisee_list: [],
		franchisee_selected: null,
		project_steps_list: [
			{
				id: 'general',
				name: 'Geral',
			},
			{
				id: 'prospecting',
				name: 'Prospecção',
			},
			{
				id: 'waterscan',
				name: 'Waterscan',
			},
		],
		project_steps_selected: {
			id: 'general',
			name: 'Geral',
		},
		project_franchisee_list: [],
		steps_informations: {
			'id_lead': {
				step: 'prospecting',
				next_step: 'waterscan',
			},
			'id_waterscan': {
				step: 'waterscan',
				next_step: 'waterscan',
			},
		}
	}),

	computed: {
		...mapGetters([
			"getCurrentUserData",
			"getFranqueados",
		]),
		getItems() {
			let items = this.items
			let search = ''
			function filterFranchisee(id_franchisee) {
				if (id_franchisee == 'general') return
				items = items.filter(
					(item) => {
						return item.id_franchisee === id_franchisee
					}
				);
			}
			function filterProjectStep(step) {
				if (step == 'general') return
				items = items.filter(
					(item) => {
						return item.next_step === step
					}
				);
			}
			function searchProject(search, searchText) {
				searchText = Utils.defaultSpace(searchText)
				items = items.filter(
					(item) => {
						if (searchText == '') return true
						if (!item[search]) return false
						return Utils.defaultSpace(item[search]).toLowerCase().indexOf(searchText.toLowerCase()) > -1
					}
				);
			}
			filterFranchisee(this.franchisee_selected.id)
			filterProjectStep(this.project_steps_selected.id)
			switch (this.searchOptionSelected) {
				case 'Nome do Projeto':
					search = 'project_name'
					searchProject(search, this.searchField)
					break;
				case 'Cliente':
					search = 'lead_name'
					searchProject(search, this.searchField)
					break;
				case 'Documento (CPF/CNPJ)':
					search = 'lead_document'
					searchProject(search, this.searchField)
					break;
				default:
					searchProject(null, '')
			}
			return items
		},
	},

	methods: {
		...mapActions([
			"publishChannel",
		]),
		openDialog() {
			this.dialog = true
			this.form = {
				project_name: null,
				lead_document: null,
				id_franchisee: null,
			}
			this.type_lead_document_selected = 'CPF'
		},
		closeDialog() {
			this.dialog = false
		},
		async createProject() {
			// this.dialog = false
			this.is_loading = true
			if (!this.$refs.newProjectForm.validate()) return
			let params = {
				"document": this.form.lead_document,
				"id_franchisee": this.form.id_franchisee.id
			}
			// Requisitar se existe algum projeto associado ao franqueado com o documento do lead
			const [status, lead] = await LeadsService.searchLeadByDocument(
				this.form.lead_document,
				this.form.id_franchisee.id,
			)
			if (!status) {
				this.is_loading = false
				return
			}
			let isNewLead = lead === null
			// Carregar o lead ou criar um novo
			if (isNewLead) {
				this.publishChannel({
					channel: 'projects',
					event: 'leads:create',
					value: [
						this.form.id_franchisee.id,
						this.form.lead_document,
						this.form.project_name,
						this.type_lead_document_selected,
					],
				})
			} else {
				this.publishChannel({
					channel: 'projects',
					event: 'leads:get',
					value: [
						this.form.project_name,
						this.type_lead_document_selected,
						lead,
					],
				})
			}
			this.is_loading = false
		},
		ruleRequired() {
			return Rules.required()
		},
		ruleString(isRequired = false, max = 64) {
			return Rules.string(isRequired, 1, max)
		},
		ruleCPF(isRequired) {
			return Rules.cpf(isRequired)
		},
		ruleCNPJ(isRequired) {
			return Rules.cnpj(isRequired)
		},
		navigateProject(item) {
			let project_id = item.id_doc
			this.$router.push(`/projects/${project_id}`)
		},
	},
	
	watch: {
		
	},

	async created() {
		let [status, projectList] = await ProjectsService.getProjects()
		projectList = projectList.map(
			(project) => {
				let created_at = new Date(project.created_at._seconds * 1000)
				return {
					...project,
					created_at,
					created_at_formatted: Utils.formatDate(created_at, '<dd>/<MM>/<YYYY>')
				}
			}
		)
		this.items = projectList
		let id_steps = Object.keys(this.steps_informations).reverse()
		this.items = this.items.map(
			(item) => {
				let current_id_step = null
				for (let id_step of id_steps) {
					if (item.hasOwnProperty(id_step)) {
						current_id_step = id_step
						break;
					}
				}
				if (current_id_step === null) return {
					...item,
					current_step: null,
					next_step: 'prospecting',
				}
				return {
					...item,
					current_step: this.steps_informations[current_id_step].step,
					next_step: this.steps_informations[current_id_step].next_step,

				}
			}
		)
		const response = await UsersService.findAllFranchisee()
		this.franchisee_list = []
		this.project_franchisee_list = []
		if (response.status == 200) {
			this.franchisee_list = [
				{
					id: 'general',
					name: 'Geral',
				},
				...response.data.data
			]
			this.project_franchisee_list = response.data.data
		} else {
			showErrorSnackBar("Ocorreu um erro na busca da lista de franqueados")
		}
		this.franchisee_list = this.franchisee_list.map(({ id, name }) => ({ id, name }))
		if (this.franchisee_list.length > 0) {
			this.franchisee_list = [
				{
					id: 'general',
					name: 'Geral',
				},
				...this.franchisee_list
			]
			this.franchisee_selected = {
				id: 'general',
				name: 'Geral',
			}
		}
		this.is_created = true
		this.is_loading = false
	},
};