<template>
  <v-col>
    <v-row
      style="
        justify-content: space-between;
      "
    >
      <h3>{{ title }}</h3>
      <v-btn 
        @click="() => inputEl.click()"
      >
          <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>

    <v-row>
      <vue-horizontal>
        <section style=" margin: 10px;" v-for="({ url }, n) in photoList" :key="n">
          <v-img
            :src="url"
            aspect-ratio="1" 
            @click="() => removePhoto(n)" 
            style="
              width: 250px;
            "
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </section>
      </vue-horizontal>
    </v-row>

    <input 
      :multiple="multiple" 
      type="file" 
      accept="image/*" 
      style="display: none"
      @input="(e) => onInputFile(e)" 
      />
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import VueHorizontal from 'vue-horizontal';
import StorageFile from '@/services/storage/StorageFile';

const getStructPhoto = () => {
  return {
    name: '',
    filename: '',
    path: '',
    file: null,
    url: '',
    isNew: false,
    isRemove: false,
  }
}

const getUrl = async (path, filename) => {
  let filepath = `${path}/${filename}`
  let { url } = await StorageFile.getFile(filepath)
  return url
}

export default {
  components: {
    VueHorizontal,
  },
  props: {
    title: {
      type: String,
      default: 'Lista de fotos'
    },
    path: {
      type: String,
      required: true,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: -1,
    },
  },

  data: () => ({
    inputEl: null,
    list: [],
    pickedFiles: [],
    isLoading: false,
  }),
  methods: {
    onInputFile(e) {
      this.pickedFiles = Object.values(this.inputEl.files)
      this.inputEl.value = null
    },
    removePhoto(index) {
      const { name, filename, path } = this.list[index]
      const remove = async () => {
        this.list[index].isRemove = true
        await StorageFile.deleteImg(`${path}/${filename}`)
      }
      showDeleteDialogAlert(`Deseja excluir a foto ${name}?`, () => remove())
    },
    getNewPhotos() {
      return this.list.filter(({ isNew, isRemove }) => isNew && !isRemove).map(({ name, filename, path }) => ({ name, filename, path }))
    },
    getRemovedPhotos() {
      return this.list.filter(({ isNew, isRemove }) => !isNew && isRemove).map(({ name, filename, path }) => ({ name, filename, path }))
    },
    getAllPhotos() {
      return this.list.filter(({ isNew, isRemove }) => !isNew && !isRemove).map(({ name, filename, path }) => ({ name, filename, path }))
    },
    getPhotos() {
      return this.list.filter(({ isRemove }) => !isRemove).map(({ name, filename, path }) => ({ name, filename, path }))
    },
    async removeAllPhotos() {
      for (let { filename, path } of this.getPhotos()) {
        await StorageFile.deleteImg(`${path}/${filename}`)
      }
      this.list = []
    },
  },
  computed: {
    photoList() {
      return this.list.filter(({ isRemove }) => !isRemove)
    },
  },
  watch: {
    photos: {
      immediate: true,
      handler: async function(photoList) {
        if (Array.isArray(photoList)) {
          let list = []
          for (let { name, filename, path } of photoList) {
            if (!name) throw new Error("Não foi encontrado o campo 'name'");
            if (!filename) throw new Error("Não foi encontrado o campo 'filename'");
            if (!path) throw new Error("Não foi encontrado o campo 'path'");
            const indexFile = this.list.findIndex((f) => f.name === name && !f.isRemove)
            if (indexFile < 0) {
              let photo = getStructPhoto()
              photo.name = name
              photo.filename = filename
              photo.path = path
              photo.url = await getUrl(path, filename)
              photo.file = null
              photo.isNew = false
              photo.isRemove = false
              list.push(photo)
            } else {
              let file = this.list[indexFile]
              if (file.isNew) {
                list.push({
                  ...this.list[indexFile],
                  url: await getUrl(path, filename),
                  isNew: false,
                })
              }
            }
          }
          for (let index in list) {
            this.$set(this.list, index, list[index])
          }
        }
      }
    },
    async pickedFiles(files) {
      if (Array.isArray(files)) {
        let total = files.length + this.getPhotos().length
        if (this.max >= 1 && total > this.max) {
          return showErrorSnackBar(`É possível selecionar uma quantidade máxima de ${this.max} foto(s)`)
        }
        this.isLoading = true
        for (let file of files) {
          const name = file.name
          const _file = this.list.find((f) => f.name === name && !f.isRemove)
          if (!_file) {
            const filename = (new Date().getTime()) + "_" + name
            const url = URL.createObjectURL(file)
            let photo = getStructPhoto()
            photo.name = name
            photo.filename = filename
            photo.path = this.path
            photo.url = url
            photo.file = file
            photo.isNew = true
            photo.isRemove = false
            this.list.push(photo)
            this.$set(this.list, this.list.length - 1, photo)
            this.$nextTick(
              async () => {
                let index = this.list.findIndex(({ name, isRemove }) => name === photo.name && !isRemove)
                if (index >= 0) {
                  let filepath = `${photo.path}/${photo.filename}`
                  await StorageFile.uploadImageByFilepath(filepath, photo.file)
                  const { url } = await StorageFile.getFile(filepath)
                  this.list[index].url = url
                  this.list[index].file = null
                }
              }
            )
          }
        }
      }
    },
    list(photoList) {
      if (Array.isArray(photoList)) {
        let fn = setInterval(
          () => {
            this.isLoading = photoList.filter((f) => f.isNew && f.url.match(/^blob/)) > 0
            if (!this.isLoading) {
              clearInterval(fn)
            }
          }, 100
        )
      }
    },
    isLoading(status) {
      this.$emit("photos:loading", this.isLoading)
    },
  },
  mounted() {
    this.inputEl = this.$el.querySelector('input')
  }
};
</script>