<template>
  <v-form ref="checkListForm" v-model="validForm">
    <div>
      <v-row>
        <v-col cols="12">
          <h3>Dados do Projeto:</h3>
        </v-col>
      </v-row>
      <!-- Start v-select .... -->
      <v-row>
        <v-col :cols="cols" :md="md" :lg="lg" style="margin-bottom: -30px">
          <v-select
            outlined
            label="Tipo do Projeto"
            :items="options"
            v-model="checkListObj.dataProject.type"
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-select>
        </v-col>
      </v-row>
      <!-- End v-select .... -->
      <!-- Start Form Type Cond. Residencial / Cond. Empresarial / Cond. Misto / Comercial / Indústria / Orgão Público -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null &&
          checkListObj.dataProject.type !== 'home'
        "
      >
        <v-col cols="12">
          <h3>Dados do Local:</h3>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Torres / Blocos/ Galpões "
            type="Number"
            v-model="checkListObj.dataProject.building_type"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Pavimentos"
            type="Number"
            v-model="checkListObj.dataProject.amount_area"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Unidades por pavimento"
            type="Number"
            v-model="checkListObj.dataProject.building_by_area"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Wcs por unidade"
            type="Number"
            v-model="checkListObj.dataProject.wcs_by_unit"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Total de Wcs"
            type="Number"
            v-model="checkListObj.dataProject.amount_wcs"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Total de Unidades"
            type="Number"
            v-model="checkListObj.dataProject.amount_units"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Cond. Residencial / Cond. Empresarial / Cond. Misto / Comercial / Indústria / Orgão Público -->
      <!-- Start Form Type  Apto Individual / Casa -->
      <v-row
        v-if="
          checkListObj.dataProject.type == 'single_apartment' ||
          checkListObj.dataProject.type == 'home'
        "
      >
        <v-col cols="12">
          <h3>Dados do Local:</h3>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Usuários"
            type="Number"
            prepend-inner-icon="person"
            v-model="checkListObj.dataProject.usersAmount"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Total de Wcs"
            type="Number"
            prepend-inner-icon="mdi-shower"
            v-model="checkListObj.dataProject.wcsAmount"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Chuveiro Elértico"
            type="Number"
            prepend-inner-icon="mdi-shower-head"
            v-model="checkListObj.dataProject.showerEletric"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Chuveiro a Gás"
            type="Number"
            prepend-inner-icon="mdi-shower-head"
            v-model="checkListObj.dataProject.showerGas"
            required
            :rules="rulesRequired"
            @change="someFieldHasChanged"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type  Apto Individual / Casa -->
      <!-- Start Form Type Hydrometer -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null
        "
       
      >
        <v-col cols="12">
          <h3>
            Hidrômetro:
            <v-btn
                color="primary"
                rounded
                text    
                @click="openAndCloseDialogAdded('hydrometer',true)"          
              >
                <v-icon>add</v-icon>
              </v-btn>
          </h3>
        </v-col>

        <DialogHydrometer 
          :dialog="dialogHydrometer" 
          :saveFunc="openAndCloseDialogAdded"
          @status:save="someFieldHasUpdated"
        />

        <CardsWaterScan  
          width="240" 
          v-for="(hydrometer, index) in hydrometerList" 
          :key="index"
          :index="index"
          :type="'hydrometer'"
          :deleteFunc="deleteItenList"
          @status:save="someFieldHasUpdated"
        >
          <div style="font-size: 16px;">
            <span style="font-weight: 500;">Hidrômetro</span>
              - {{hydrometer.locale}} 
          </div>
          <v-divider color="white" />
          <div style="font-size: 16px;">
            {{hydrometer.supply}} - {{hydrometer.model}}
          </div>
        </CardsWaterScan>
 
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Hydrometer -->
      <!-- Start Form Type Bombs -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null
        "
      >
        <v-col cols="12">
          <h3>Bombas:   <v-btn
                  color="primary"
                  rounded
                  text   
                  @click="openAndCloseDialogAdded('bomb',true)"              
                >
                  <v-icon>add</v-icon>
                </v-btn>
            </h3>
        </v-col>

        <DialogBombs 
          :dialog="dialogBomb"
          :saveFunc="openAndCloseDialogAdded" 
          @status:save="someFieldHasUpdated"
        />

        <!-- OK -->
        <CardsWaterScan 
          width="295" 
          v-for="(bomb, index) in bombList" 
          :key="index"
          :index="index"
          :type="'bomb'"
          :deleteFunc="deleteItenList"
          @status:save="someFieldHasUpdated"
        >
          <div style="font-size: 16px;">
            <span style="font-weight: 500;">Bomba</span>
              - {{bomb.locale}} <v-icon v-if="showImgIcon(bomb)" color="white" small> mdi-image </v-icon>
          </div>          
          <v-row>
            <v-col :cols="12"    style="padding: 6px"  >
            <v-divider color="white" />
            </v-col>
            <v-col :cols="6" >
              Tipo: {{bomb.reservoir}} 
            </v-col>
            <v-col :cols="6" >
              Uso por Dia: {{bomb.hour_day_use}} H
            </v-col>
            <v-col :cols="6" >
              Kw/h: R$ {{bomb.value_kwh}} 
            </v-col>
            <v-col :cols="6" >
              Potência: {{bomb.watt}} W 
            </v-col>
          </v-row>
        </CardsWaterScan>

        <v-col cols="12"><v-divider  style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Bombs -->
      <!-- End Form Type Resevoir -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null
        "
      >
        <v-col cols="12">
          <h3>Reservatório:   <v-btn
              color="primary"
              rounded
              text  
              @click="openAndCloseDialogAdded('reservoir',true)"              
            >
              <v-icon>add</v-icon>
            </v-btn>
          </h3>
        </v-col>

        <!-- OK -->
        <DialogReservoir 
          :dialog="dialogReservoir" 
          :saveFunc="openAndCloseDialogAdded"
          @status:save="someFieldHasUpdated"
        />

        <!-- OK -->
        <CardsWaterScan 
          width="230" 
          v-for="(reservoir, index) in reservoirList" 
          :key="index"
          :index="index"
          :type="'reservoir'"
          :deleteFunc="deleteItenList"
          @status:save="someFieldHasUpdated"
        >
          <div style="font-size: 16px;">
          <span style="font-weight: 500;">Reservatório</span>  - {{reservoir.locale}}
          </div>          

            <v-divider color="white" />
        
          <div style="font-size: 16px;">
            {{reservoir.type}} - {{reservoir.capacity}} L
          </div>
        </CardsWaterScan>

        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Resevoir -->  
      <ConsumptionSolutions
        :proposal="proposal"
        @consumption-solution:save="solutionUpdate"
      />
      <v-row>
        <v-col cols="12">
          <h3>Observações Gerais:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-checkbox
            v-model="checkListObj.dataProject.leaks"
            label="Existe Vazamentos?"
            @change="someFieldHasChanged"
          ></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="checkListObj.dataProject.registerFaulty"
            label="Registros com defeito?"
            @change="someFieldHasChanged"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Observações Gerais"
            outlined
            counter="128"
            v-model="checkListObj.dataProject.obsGeneral"
            @change="someFieldHasChanged"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>    
  </v-form>
  
</template>
<script>
import Rules from "@/utils/Rules";
import ConsumptionForm from "@/components/FormsComponents/WaterFix/ConsumptionTest.vue";
import ConsumptionSolutions from "@/components/Waterfix/CheckListTab/ConsumptionSolutions.vue";
import firebase from "../../../firebase";

import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import CardsWaterScan from "@/components/Forms/CardsWaterScan/CardsWaterScan.vue";
import DialogHydrometer from "@/components/FormsComponents/DialogCheckList/Hydrometer.vue";
import DialogBombs from "@/components/FormsComponents/DialogCheckList/Bombs.vue";
import DialogReservoir from "@/components/FormsComponents/DialogCheckList/Reservoir.vue";
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import { mapActions, mapGetters } from "vuex";


export default {
  props: {
    requestData: {
      default: null,
    },
    requestSave: {
      default: null,
    },
    registration: {
      default: ""
    },
    viewOrEdit: {
      default: ""
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  name: "addressForm",
  components: {
    ConsumptionForm,
    CardsWaterScan,
    DialogHydrometer,
    DialogBombs,
    DialogReservoir,
    ConsumptionSolutions,
  },
  data: function () {
    return {
      cols: "12",
      md: "6",
      lg: "4",

      mdApt: "3",
      lgApt: "3",
      xlApt: "3",
      smApt: "3",

      dialogHydrometer: false,
      dialogBomb: false,
      dialogReservoir: false,
      
      hydrometerList: [],
      bombList: [],
      reservoirList: [],
      

      validForm: true,
      validFormSolutionList: false,

      requestDataConsumpitonForm: true,
      responseConsumptionForm: [],

      checkListObj: {
        dataProject: { type: null },
        solutionList: [],
      },
      options: [
        { text: "Apto Individual", value: "single_apartment" },
        { text: "Casa", value: "home" },
        { text: "Cond. Residencial", value: "residential_condominium" },
        { text: "Cond. Empresarial", value: "business_condominium" },
        { text: "Cond. Misto", value: "mixed_condominium" },
        { text: "Comercial", value: "commercial" },
        { text: "Industrial", value: "industry" },
        { text: "Orgão Público", value: "public_agency" },
      ],
      optionsSupply: ["Poço", "Concessionária"],
      optionsHydrometer: [
        { text: "DN 15", value: "dn_15" },
        { text: "DN 20", value: "dn_20" },
        { text: "DN 25", value: "dn_25" },
        { text: "DN 32", value: "dn_32" },
        { text: "DN 40", value: "dn_40" },
        { text: "DN 50", value: "dn_50" },
        { text: "DN 80", value: "dn_80" },
        { text: "DN 110", value: "dn_110" },
      ],
      optionsReservoir: ["Cisterna", "Caixa d'água"],
      optionsBomb: ["Poço", "Recalque"],

      // --------------------------------------------------------
      // --------------------------------------------------------
      // --------------------------------------------------------

      index: 1,
      thereAreChanges: false,
      hasDocumentCreatedInWaterFix: false,
      proposalId: null,
      enabledAutomaticSaved: true,
      proposal: null,
    };
  },
  methods: {
    ...mapActions([
      'initWaterFixLocals',
      'initWaterFixSublocals',
      'subscriberWaterfix',
      'subscribeChannel',
      'publishChannel',
    ]),
    solutionUpdate(solutionList) {
      this.checkListObj.solutionList = solutionList
      this.proposal.solutionList = solutionList
      this.someFieldHasUpdated(true)
      this.emitSavePageEvent()
    },
    osListUpdate(osList) {
      this.checkListObj['osList'] = osList
      this.someFieldHasUpdated(true)
      this.emitSavePageEvent()
    },
    showImgIcon(line) {
      if(line.imgList && line.imgList.length > 0){
        return true
      }
      return false
    },
    async deleteImg(filePathList) {
      for(let filePath of filePathList){
        await WaterScanStoragefrom.deleteImg(filePath)
      }
    },
    async deleteItenList(type,deleteIndex){
      let deleteItem = null
      switch(type){
        case "hydrometer":
          deleteItem = this.hydrometerList.filter((i,index) => index === deleteIndex )
          if(deleteItem[0].imgList)await this.deleteImg(deleteItem[0].imgList)

          this.hydrometerList = this.hydrometerList.filter((i,index) => index != deleteIndex )
          this.checkListObj.dataProject.hydrometerList = this.hydrometerList
          break;
        case "bomb":
          deleteItem = this.bombList.filter((i,index) => index === deleteIndex )
          if(deleteItem[0].imgList)await this.deleteImg(deleteItem[0].imgList)

          this.bombList = this.bombList.filter((i,index) => index != deleteIndex )
          this.checkListObj.dataProject.bombList = this.bombList
          break;
        case "reservoir":
          deleteItem = this.reservoirList.filter((i,index) => index === deleteIndex )
          if(deleteItem[0].imgList)await this.deleteImg(deleteItem[0].imgList)

          this.reservoirList = this.reservoirList.filter((i,index) => index != deleteIndex )
          this.checkListObj.dataProject.reservoirList = this.reservoirList
          break;
        default:
          console.error("Type does not exist!")
      }      
    },
    async saveImgs(rawFiles){
      let savedFileNames = [];
      for (let file of rawFiles) {
        savedFileNames.push(await WaterScanStoragefrom.uploadImg(this.registration, file))
      }
      return savedFileNames
    },
    async openAndCloseDialogAdded(type, openOrClose = false, data = null){

      switch(type){
        case "hydrometer":
        this.dialogHydrometer = openOrClose
        if(data){
          data.imgList = await this.saveImgs(data.imgList)
          this.hydrometerList.push(data)
          this.checkListObj.dataProject.hydrometerList = this.hydrometerList
        } 
          break;
        case "bomb":
        this.dialogBomb = openOrClose
        if(data){ 
          data.imgList = await this.saveImgs(data.imgList)
          this.bombList.push(data)
          this.checkListObj.dataProject.bombList = this.bombList
        }
          break;
        case "reservoir":
        this.dialogReservoir = openOrClose
        if(data) {
          data.imgList = await this.saveImgs(data.imgList)
          this.reservoirList.push(data)
          this.checkListObj.dataProject.reservoirList = this.reservoirList
        }
          break;
        default:
          console.error("Type does not exist!")
      }
    },
    // ---------------------------------------------------------------------
    // ---------------------------------------------------------------------
    // ---------------------------------------------------------------------
    async updateProposalinFirebase() {
      if (this.proposalId === null) {
        let proposalObject = await this.getProposalFromWaterFix(this.registration)
        this.proposalId = proposalObject.id
      }
      this.emitLoadingEvent(true)
      await Utils.sleep(250)
      // if (this.hasDocumentCreatedInWaterFix) {
      try {
        await WaterFixService.updateProposal(this.proposalId, this.checkListObj)
        this.emitLoadingEvent(false)
      } catch (e) {
        this.emitLoadingEvent(false)
        alert("Erro ao ATUALIZAR o CheckList!");
      }
      // }
    },
    emitLoadingEvent(status) {
      this.$emit("loading", status);
    },
    emitSavePageEvent() {
      this.$emit("page:save", 'CheckList');
      this.enabledAutomaticSaved = true;
    },
    async getProposalFromWaterFix(registration) {
      // Verificar se existe alguma proposta de water fix
      return await WaterFixService.getProposalByRegistration(registration)
    },
    async setCheckList(dataProject, solutionList) {
      if (dataProject) {
        this.checkListObj.dataProject = dataProject
        this.reservoirList = dataProject.reservoirList || []
        this.bombList = dataProject.bombList || []
        this.hydrometerList = dataProject.hydrometerList || []
      } else {
        this.checkListObj.dataProject = { type: null }
      }
      if (solutionList) {
        this.checkListObj.solutionList = solutionList
        await this.initWaterFixLocals({
          id: this.proposalId,
          locals: solutionList.map((item) => item.local)
        })
        await this.initWaterFixSublocals({
          id: this.proposalId,
          sublocals: solutionList.map((item) => item.sub_local)
        })
      }
    },
    setThereAreChanges(status) {
      this.thereAreChanges = status;
    },
    someFieldHasChanged() {
      this.setThereAreChanges(true);
    },
    someFieldHasUpdated(status) {
      if (status) {
        this.enabledAutomaticSaved = false;
        this.someFieldHasChanged()
        this.emitSavePageEvent()
      }
    },
    isValidadePage() {
      if (this.$refs.checkListForm && !this.$refs.checkListForm.validate()) {
        return false;
      }
      return true;
    },
    saveAll() {
      if (!this.thereAreChanges) return;
      this.updateProposalinFirebase()
      this.setThereAreChanges(false)
    },
    updateLocalListener(beforeId, afterId, text) {
      this.checkListObj.solutionList = this.checkListObj.solutionList.map(
        (solution) => {
          let localId = Utils.createSlug(solution.local)
          if (beforeId !== localId) return solution;
          return {
            ...solution,
            local: text,
          }
        }
      )
      this.setThereAreChanges(true)
      this.saveAll();
    },
    updateSublocalListener(beforeId, afterId, text) {
      this.checkListObj.solutionList = this.checkListObj.solutionList.map(
        (solution) => {
          let sublocalId = Utils.createSlug(solution.sub_local)
          if (beforeId !== sublocalId) return solution;
          return {
            ...solution,
            sub_local: text,
          }
        }
      )
      this.setThereAreChanges(true)
      this.saveAll();
    },
  },
  computed: {
    ...mapGetters([
        'waterfixLocals',
    ]),
    rulesRequired() {
      return Rules.required();
    },
    rulesCEP() {
      return Rules.cep();
    },
    rulesLogradouro() {
      return Rules.string(false, null, 128);
    },
    rulesCity() {
      return Rules.string(false, null, 64);
    },
    rulesDistrict() {
      return Rules.string(false, null, 64);
    },
    rulesAdditionalAddress() {
      return Rules.string(false, null, 64);
    },
    rulesHouseNumber() {
      return Rules.string(false, null, 16);
    },
  },
  watch: {
    thereAreChanges(newValue, oldValue) {
      if (newValue && this.enabledAutomaticSaved) {
        Utils.debounce(
          () => {
            this.emitSavePageEvent()
          }, 3000
        )()
      }
    },
  },
  async created() {
    this.subscribeChannel({
      channel: 'waterfix',
      events: [
        {
          event: 'osList',
          fn: ({ local, list }) => {
            let local_slug = Utils.createSlug(local)
            let osList = this.checkListObj.hasOwnProperty('osList') ? this.checkListObj.osList : {}
            osList[local_slug] = list
            if (osList[local_slug].length <= 0) {
              delete osList[local_slug]
            }
            this.osListUpdate(osList)
          }
        },
        {
          event: 'consumption-solutions:loading',
          fn: (status) => {
            this.emitLoadingEvent(status)
          }
        },
      ]
    })
    let proposalFromWaterFix = await this.getProposalFromWaterFix(this.registration);
    if (proposalFromWaterFix) {
      this.proposal = proposalFromWaterFix
      this.proposalId = proposalFromWaterFix.id;
      this.setCheckList(
        proposalFromWaterFix.dataProject, 
        proposalFromWaterFix.solutionList
      )
      this.hasDocumentCreatedInWaterFix = true;
      return;
    } else {
      this.hasDocumentCreatedInWaterFix = false;
    }
  },
};
</script>