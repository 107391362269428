import { mapActions, mapGetters } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

import LeadsService from "@/services/server/LeadsService.js";
import ProjectsService from "@/services/server/ProjectsService.js";

export default {
	name: "Prospecção",

    // props: {
    //     id_project: {
    //         type: String,
    //         default: null,
    //     },
    //   },

	data: () => ({
		is_loading: true,
        is_created: false,
        is_new_project: false,
        is_create_lead: false,
        dialog: false,
        radioDocument: null,
        radioDocumentOptions: ['CPF', 'CNPJ'],
        project_name: null,
        id_project: null,
        isValidForm: false,
        prospecting: {
            id_franchisee: null,
            name: null,
            document: null,
            phone: null,
            email: null,

            zip_code: null,
            uf: null,
            city: null,
            district: null,
            address: null,
            house_number: null,
            address_complement: null,

            responsible_name: null,
            responsible_document: null,
            responsible_phone: null,
            responsible_email: null,
            // responsible_role: null,

            registration: null,
            minimum_rate: null,
            financial_average_per_unit: null,
            number_units: null,
            number_towers: null,
            number_floors: null,
            unit_by_floor: null,
            wcs_by_unit: null,
        },
        state_acronyms: [
            "AC",
            "AL",
            "AP",
            "AM",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MT",
            "MS",
            "MG",
            "PA",
            "PB",
            "PR",
            "PE",
            "PI",
            "RJ",
            "RN",
            "RS",
            "RO",
            "RR",
            "SC",
            "SP",
            "SE",
            "TO",
        ],
	}),

	computed: {
		
	},

	methods: {
		...mapActions([
			"subscribeChannel",
		]),
        clearProspecting() {
            this.dialog = false;
            this.$nextTick(
                () => {
                    this.prospecting = {
                        id_franchisee: null,
                        name: null,
                        document: null,
                        phone: null,
                        email: null,
        
                        zip_code: null,
                        uf: null,
                        city: null,
                        district: null,
                        address: null,
                        house_number: null,
                        address_complement: null,
        
                        responsible_name: null,
                        responsible_document: null,
                        responsible_phone: null,
                        responsible_email: null,
                        // responsible_role: null,
        
                        registration: null,
                        minimum_rate: null,
                        financial_average_per_unit: null,
                        number_units: null,
                        number_towers: null,
                        number_floors: null,
                        unit_by_floor: null,
                        wcs_by_unit: null,
                    }
                    this.is_loading = true;
                    this.is_created = false;
                    this.is_new_project = false;
                    this.is_create_lead = false;
                    this.radioDocument = null;
                    this.project_name = null;
                    this.id_project = null;
                }
            )
        },
        close() {
            this.clearProspecting()
        },
        async save() {
            if (!this.$refs.propectingForm.validate()) {
                showErrorSnackBar("Há pendência(s) no formulário de prospecção")
                return
            }
            this.is_loading = true
            this.prospecting.financial_average_per_unit = this.prospecting.financial_average_per_unit ? parseFloat(this.prospecting.financial_average_per_unit) : null
            this.prospecting.minimum_rate = this.prospecting.minimum_rate ? parseFloat(this.prospecting.minimum_rate) : null
            this.prospecting.number_floors = this.prospecting.number_floors ? parseInt(this.prospecting.number_floors) : null
            this.prospecting.number_towers = this.prospecting.number_towers ? parseInt(this.prospecting.number_towers) : null
            this.prospecting.number_units = this.prospecting.number_units ? parseInt(this.prospecting.number_units) : null
            this.prospecting.registration = this.prospecting.registration ? parseInt(this.prospecting.registration) : null
            this.prospecting.unit_by_floor = this.prospecting.unit_by_floor ? parseInt(this.prospecting.unit_by_floor) : null
            this.prospecting.wcs_by_unit = this.prospecting.wcs_by_unit ? parseInt(this.prospecting.wcs_by_unit) : null
            if (this.is_new_project) {
                let id_lead = null
                if (this.is_create_lead) {
                    let [status_lead, response_lead] = await LeadsService.createLead(this.prospecting)
                    if (status_lead) {
                        id_lead = response_lead.id_lead
                    }
                } else {
                    id_lead = this.prospecting.id_doc
                    let [status_lead, response_lead] = await LeadsService.updateLead(id_lead, { ...this.prospecting })
                }

                // Criar um projeto
                let [status_project, response_project] = await ProjectsService.createProject({
                    id_lead,
                    project_name: this.project_name,
                    id_franchisee: this.prospecting.id_franchisee,
                })
                
                // Redirecionar para a url do projeto
                let { id_project } = response_project
                this.is_loading = false
                this.$router.push(`/projects/${id_project}`)
            } else {
                let id_lead = this.prospecting.id_doc
                let [status_lead, response_lead] = await LeadsService.updateLead(id_lead, { ...this.prospecting })
                this.clearProspecting()
            }
        },
        ruleRequired() {
			return Rules.required()
		},
		ruleString(isRequired = false, max = 64) {
			return Rules.string(isRequired, 1, max)
		},
		ruleCPF(isRequired) {
			return Rules.cpf(isRequired)
		},
		ruleCNPJ(isRequired) {
			return Rules.cnpj(isRequired)
		},
        ruleEmail(isRequired) {
            return Rules.email(isRequired, 64)
        },
        ruleCEP(isRequired) {
            return Rules.cep(isRequired)
        },
        ruleIntPositive(isRequired) {
            return Rules.number(isRequired)
        },
        searchCEP(CEP) {
            if (!CEP) return
            if (!CEP.match(/^\d{2}\.\d{3}\-\d{3}$/)) return
            if (
                this.prospecting.uf ||
                this.prospecting.city ||
                this.prospecting.district ||
                this.prospecting.address ||
                this.prospecting.house_number || 
                this.prospecting.address_complement
            ) {
                return
            }
            this.is_loading = true
            this.prospecting.uf = null
            this.prospecting.city = null
            this.prospecting.district = null
            this.prospecting.address = null
            this.prospecting.house_number = null
            this.prospecting.address_complement = null
            let url = "https://viacep.com.br/ws/" + CEP.replace(/\D/g, "") + "/json/";
            fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                var { bairro, localidade, logradouro, uf } = responseJson
                this.prospecting.uf = uf
                this.prospecting.city = localidade
                this.prospecting.district = bairro
                this.prospecting.address = logradouro
                this.is_loading = false
            })
            .catch((err) => {
                this.is_loading = false
                console.error("Erro", err)
            });
            this.is_loading = false
        },
	},
	
	watch: {
		'prospecting.zip_code': function (newVal) {
            this.searchCEP(newVal)
        }
	},

	async created() {
        this.subscribeChannel({
            channel: 'projects',
            events: [
                {
                    event: 'leads:create',
                    fn: ([
                        id_franchisee, 
                        lead_document, 
                        project_name, 
                        document_type,
                    ]) => {
                        this.is_new_project = true
                        this.is_loading = false;
                        this.id_project = null
                        this.dialog = true
                        this.prospecting.id_franchisee = id_franchisee
                        this.radioDocument = document_type
                        this.prospecting.document = lead_document
                        this.project_name = project_name
                        this.is_create_lead = true
                        this.is_created = true;
                    }
                },
                {
                    event: 'leads:get',
                    fn: ([
                        project_name, 
                        document_type,
                        lead,
                    ]) => {
                        this.is_new_project = true
                        this.is_loading = false;
                        this.dialog = true
                        this.id_project = null
                        // this.prospecting.id_franchisee = id_franchisee
                        this.radioDocument = document_type
                        // this.prospecting.document = lead_document
                        this.project_name = project_name
                        this.prospecting = lead
                        this.is_create_lead = false
                        this.is_created = true;
                    }
                },
                {
                    event: 'leads:open',
                    fn: async ([
                        id_lead,
                        id_project
                    ]) => {
                        this.is_new_project = false
                        this.is_loading = true;
                        this.dialog = true

                        // Buscar o lead
                        const [status, lead] = await LeadsService.getLeadById(
                            id_lead,
                        )
                        if (!status) {
                            showErrorSnackBar("O lead não foi encontrado")
                            this.is_loading = false;
                            this.dialog = false;
                            return
                        }
                        this.id_project = id_project
                        if (lead.document.match(/^\d{2}\.\d{3}\.\d{3}\/\d{3}$/)) {
                            this.radioDocument = 'CNPJ'
                        } else {
                            this.radioDocument = 'CPF'
                        }
                        this.is_create_lead = false
                        this.is_created = true;
                        this.$nextTick(
                            () => {
                                Object.keys(lead).forEach(
                                    (key) => {
                                        this.$set(this.prospecting, key, lead[key])  
                                    }
                                )
                                this.is_loading = false;
                            }
                        )
                    }
                },
            ]
        })
        
    },
};