import axios from "axios";
import { showErrorSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
import Utils from "@/scripts/Utils";

export var HOST = "http://127.0.0.1:5001/centralfoz-homolog/us-central1/server/api/v1/"

switch (Utils.getEnv()) {
    case 'local':
        HOST = "https://us-central1-centralfoz-homolog.cloudfunctions.net/server/api/v1/"
        break;
    case 'homolog':
        HOST = "https://us-central1-centralfoz-homolog.cloudfunctions.net/server/api/v1/"
        break;
    case 'prod':
        HOST = "https://us-central1-sistema-foz.cloudfunctions.net/server/api/v1/"
        break;
}

const apiService = axios.create({
    baseURL: HOST,
    timeout: 60000,
});
apiService.defaults.headers.common['Authorization'] = "123123";

apiService.interceptors.response.use((response) => response, (error) => {
    if (!error.response || error.response.status == 500) {
        showErrorSnackBar("Ocorreu um erro não identificado, entre em contato com nossa equipe de suporte.")
        console.error("Response error", error);
    } else {
        showInfoSnackBar(error.response.data.message)
    }
    throw error;
});

export default apiService