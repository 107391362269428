

import { storage } from "@/firebase"
import Compressor from 'compressorjs';

const compressorFile = async (file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.4,
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err)
                console.error(err.message);
            },
        });
    })
}
const uploadImg = async (register, file) => {
    const fileCompress = await compressorFile(file)
    const imgNameSaved = new Date().getTime() +"_"+file.name
    const pathSaved = `water_scan_imgs/${register}/${imgNameSaved}`
    const storageWS_REF = storage.ref(pathSaved)
    var task = storageWS_REF.put(fileCompress);
    await new Promise((resolve, reject) => {
        task.on('state_changed',
            function progress(snapshot) {
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            },
            function error(err) {
                reject(err)
            },
            function complete() {
                var downloadURL = task.snapshot.downloadURL;
                resolve("Download Complet")
            }
        );
    })
    return pathSaved
}
const downloadImg = async (path) => {
    return storage.ref(path).getDownloadURL() 
}
const deleteImg = async (path) => {
    await storage.ref(path).delete()
}

export default {
    uploadImg,
    deleteImg,
    downloadImg
}