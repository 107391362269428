<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <div>
      <v-row>
        <v-col cols="12">
          <h3>Dados do Projeto:</h3>
        </v-col>
      </v-row>
      <!-- Start v-select .... -->
      <v-row>
        <v-col :cols="cols" :md="md" :lg="lg" style="margin-bottom: -30px">
          <v-select
            outlined
            label="Tipo do Projeto"
            :items="options"
            v-model="checkListObj.dataProject.type"
            :rules="rulesRequired"
          >
          </v-select>
        </v-col>
      </v-row>
      <!-- End v-select .... -->
      <!-- Start Form Type Cond. Residencial / Cond. Empresarial / Cond. Misto / Comercial / Indústria / Orgão Público -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null &&
          checkListObj.dataProject.type !== 'home'
        "
      >
        <v-col cols="12">
          <h3>Dados do Local:</h3>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Torres / Blocos/ Galpões "
            type="Number"
            v-model="checkListObj.dataProject.building_type"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Pavimentos"
            type="Number"
            v-model="checkListObj.dataProject.amount_area"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Unidades por pavimento"
            type="Number"
            v-model="checkListObj.dataProject.building_by_area"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Wcs por unidade"
            type="Number"
            v-model="checkListObj.dataProject.wcs_by_unit"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Total de Wcs"
            type="Number"
            v-model="checkListObj.dataProject.amount_wcs"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Total de Unidades"
            type="Number"
            v-model="checkListObj.dataProject.amount_units"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Cond. Residencial / Cond. Empresarial / Cond. Misto / Comercial / Indústria / Orgão Público -->
      <!-- Start Form Type  Apto Individual / Casa -->
      <v-row
        v-if="
          checkListObj.dataProject.type == 'single_apartment' ||
          checkListObj.dataProject.type == 'home'
        "
      >
        <v-col cols="12">
          <h3>Dados do Local:</h3>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Usuários"
            type="Number"
            prepend-inner-icon="person"
            v-model="checkListObj.dataProject.usersAmount"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Total de Wcs"
            type="Number"
            prepend-inner-icon="mdi-shower"
            v-model="checkListObj.dataProject.wcsAmount"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Chuveiro Elértico"
            type="Number"
            prepend-inner-icon="mdi-shower-head"
            v-model="checkListObj.dataProject.showerEletric"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
          <v-text-field
            outlined
            label="Chuveiro a Gás"
            type="Number"
            prepend-inner-icon="mdi-shower-head"
            v-model="checkListObj.dataProject.showerGas"
            required
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type  Apto Individual / Casa -->
      <!-- Start Form Type Hydrometer -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null
        "
       
      >
        <v-col cols="12">
          <h3>
            Hidrômetro:
            <v-btn
                color="primary"
                rounded
                text    
                @click="openAndCloseDialogAdded('hydrometer',true)"          
              >
                <v-icon>add</v-icon>
              </v-btn>
          </h3>
        </v-col>
        <DialogHydrometer :dialog="dialogHydrometer" :saveFunc="openAndCloseDialogAdded"/>

          <CardsWaterScan  width="240" v-for="(hydrometer, index) in hydrometerList" :key="index"
          :index="index"
          :type="'hydrometer'"
          :deleteFunc="deleteItenList" >
            <div style="font-size: 16px;">
              <span style="font-weight: 500;">Hidrômetro</span>
               - {{hydrometer.locale}} 
            </div>
            <v-divider color="white" />
            <div style="font-size: 16px;">
              {{hydrometer.supply}} - {{hydrometer.model}}
            </div>
          </CardsWaterScan>
 
       
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Hydrometer -->
      <!-- Start Form Type Bombs -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null
        "
      >
        <v-col cols="12">
          <h3>Bombas:   <v-btn
                  color="primary"
                  rounded
                  text   
                  @click="openAndCloseDialogAdded('bomb',true)"              
                >
                  <v-icon>add</v-icon>
                </v-btn>
            </h3>
        </v-col>
        <DialogBombs :dialog="dialogBomb"
        :saveFunc="openAndCloseDialogAdded" 
            />
        <CardsWaterScan width="295" v-for="(bomb, index) in bombList" :key="index"
        :index="index"
          :type="'bomb'"
          :deleteFunc="deleteItenList"
          >
            <div style="font-size: 16px;">
              <span style="font-weight: 500;">Bomba</span>
               - {{bomb.locale}} <v-icon v-if="showImgIcon(bomb)" color="white" small> mdi-image </v-icon>
            </div>          
            <v-row>
              <v-col :cols="12"    style="padding: 6px"  >
              <v-divider color="white" />
              </v-col>
              <v-col :cols="6" >
                Tipo: {{bomb.reservoir}} 
              </v-col>
              <v-col :cols="6" >
                Uso por Dia: {{bomb.hour_day_use}} H
              </v-col>
              <v-col :cols="6" >
                Kw/h: R$ {{bomb.value_kwh}} 
              </v-col>
              <v-col :cols="6" >
                Potência: {{bomb.watt}} W 
              </v-col>

            </v-row>
          </CardsWaterScan>
        <v-col cols="12"><v-divider  style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Bombs -->
      <!-- End Form Type Resevoir -->
      <v-row
        v-if="
          checkListObj.dataProject.type !== 'single_apartment' &&
          checkListObj.dataProject.type !== null
        "
      >
        <v-col cols="12">
          <h3>Reservatório:   <v-btn
              color="primary"
              rounded
              text  
              @click="openAndCloseDialogAdded('reservoir',true)"              
            >
              <v-icon>add</v-icon>
            </v-btn>
          </h3>
        </v-col>
        <DialogReservoir :dialog="dialogReservoir" :saveFunc="openAndCloseDialogAdded"
        
        />
        <CardsWaterScan width="230" v-for="(reservoir, index) in reservoirList" :key="index"
        :index="index"
          :type="'reservoir'"
          :deleteFunc="deleteItenList"
        >
            <div style="font-size: 16px;">
            <span style="font-weight: 500;">Reservatório</span>  - {{reservoir.locale}}
            </div>          
 
              <v-divider color="white" />
          
            <div style="font-size: 16px;">
              {{reservoir.type}} - {{reservoir.capacity}} L
            </div>
          </CardsWaterScan>
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
      <!-- End Form Type Resevoir -->  
      <ConsumptionForm
        v-on:update:info="(checkListObj) => catchCheckListObj(checkListObj)"
        :requestData="requestDataConsumpitonForm"
        :solutionListItens="solutionList"
        :registration="registration"
        :idProposal="idProposal"
      />
      <v-row>
        <v-col cols="12">
          <h3>Observações Gerais:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-checkbox
            v-model="checkListObj.dataProject.leaks"
            label="Existe Vazamentos?"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="checkListObj.dataProject.registerFaulty"
            label="Registros com defeito?"
          ></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Observações Gerais"
            outlined
            counter="128"
            v-model="checkListObj.dataProject.obsGeneral"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>    
  </v-form>
  
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Rules from "@/utils/Rules";
import ConsumptionForm from "../ConsumptionTest.vue";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';

import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import CardsWaterScan from "@/components/Forms/CardsWaterScan/CardsWaterScan.vue";
import DialogHydrometer from "@/components/FormsComponents/DialogCheckList/Hydrometer.vue";
import DialogBombs from "@/components/FormsComponents/DialogCheckList/Bombs.vue";
import DialogReservoir from "@/components/FormsComponents/DialogCheckList/Reservoir.vue";

import Utils from "@/scripts/Utils";

export default {
  props: {
    requestData: {
      default: null,
    },
    requestSave: {
      default: null,
    },
    registration: {
      default: ""
    },
    idProposal: {
      default: ""
    },
    viewOrEdit: {
      default: ""
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  name: "addressForm",
  components: {
    ConsumptionForm,
    CardsWaterScan,
    DialogHydrometer,
    DialogBombs,
    DialogReservoir,
  },
  data: function () {
    return {
      cols: "12",
      md: "6",
      lg: "4",

      mdApt: "3",
      lgApt: "3",
      xlApt: "3",
      smApt: "3",

      dialogHydrometer: false,
      dialogBomb: false,
      dialogReservoir: false,
      
      hydrometerList: [],
      bombList: [],
      reservoirList: [],
      

      validForm: true,
      validFormSolutionList: false,

      requestDataConsumpitonForm: true,
      responseConsumptionForm: [],

      checkListObj: {
        dataProject: { type: null },
        solutionList: [],
      },
      solutionList: [],
      options: [
        { text: "Apto Individual", value: "single_apartment" },
        { text: "Casa", value: "home" },
        { text: "Cond. Residencial", value: "residential_condominium" },
        { text: "Cond. Empresarial", value: "business_condominium" },
        { text: "Cond. Misto", value: "mixed_condominium" },
        { text: "Comercial", value: "commercial" },
        { text: "Industrial", value: "industry" },
        { text: "Orgão Público", value: "public_agency" },
      ],
      optionsSupply: ["Poço", "Concessionária"],
      optionsHydrometer: [
        { text: "DN 15", value: "dn_15" },
        { text: "DN 20", value: "dn_20" },
        { text: "DN 25", value: "dn_25" },
        { text: "DN 32", value: "dn_32" },
        { text: "DN 40", value: "dn_40" },
        { text: "DN 50", value: "dn_50" },
        { text: "DN 80", value: "dn_80" },
        { text: "DN 110", value: "dn_110" },
      ],
      optionsReservoir: ["Cisterna", "Caixa d'água"],
      optionsBomb: ["Poço", "Recalque"],
    };
  },
  methods: {
    ...mapActions([
        'publishChannel',
    ]),
    catchCheckListObj(solutionList) {
      this.responseConsumptionForm = solutionList
      let _checkListObj = Utils.copyObject(this.checkListObj)
      _checkListObj.solutionList = solutionList
      this.solutionList = solutionList
      this.checkListObj = _checkListObj
      this.publishChannel({
        channel: 'waterscan',
        event: `solutionList:update`,
        value: solutionList,
      })
    },
    showImgIcon(line) {
      if(line.imgList && line.imgList.length > 0){
        return true
      }
      return false
    },
    async deleteImg(filePathList) {
      for(let filePath of filePathList){
        await WaterScanStoragefrom.deleteImg(filePath)
      }
    },
    async deleteItenList(type,deleteIndex){
      let deleteItem = null
      switch(type){
        case "hydrometer":
          deleteItem = this.hydrometerList.filter((i,index) => index === deleteIndex )
          try {
            if(deleteItem[0].imgList)await this.deleteImg(deleteItem[0].imgList)
          } catch (e) {
            console.error(e)
          }
          this.hydrometerList = this.hydrometerList.filter((i,index) => index != deleteIndex )
          this.checkListObj.dataProject.hydrometerList = this.hydrometerList
          break;
        case "bomb":
          deleteItem = this.bombList.filter((i,index) => index === deleteIndex )
          try {
            if(deleteItem[0].imgList)await this.deleteImg(deleteItem[0].imgList)
          } catch (e) {
            console.error(e)
          }
          this.bombList = this.bombList.filter((i,index) => index != deleteIndex )
          this.checkListObj.dataProject.bombList = this.bombList
          break;
        case "reservoir":
          deleteItem = this.reservoirList.filter((i,index) => index === deleteIndex )
          try {
            if(deleteItem[0].imgList)await this.deleteImg(deleteItem[0].imgList)
          } catch (e) {
            console.error(e)
          }
          this.reservoirList = this.reservoirList.filter((i,index) => index != deleteIndex )
          this.checkListObj.dataProject.reservoirList = this.reservoirList
          break;
        default:
          console.error("Type does not exist!")
      }
    },
    async saveImgs(rawFiles){
      let savedFileNames = [];
      for (let file of rawFiles) {
        savedFileNames.push(await WaterScanStoragefrom.uploadImg(this.idProposal, file))
      }
      return savedFileNames
    },
    async openAndCloseDialogAdded(type, openOrClose = false, data = null){

      switch(type){
        case "hydrometer":
        this.dialogHydrometer = openOrClose
        if(data){
          data.imgList = await this.saveImgs(data.imgList)
          this.hydrometerList.push(data)
          this.checkListObj.dataProject.hydrometerList = this.hydrometerList
        } 
          break;
        case "bomb":
        this.dialogBomb = openOrClose
        if(data){ 
          data.imgList = await this.saveImgs(data.imgList)
          this.bombList.push(data)
          this.checkListObj.dataProject.bombList = this.bombList
        }
          break;
        case "reservoir":
        this.dialogReservoir = openOrClose
        if(data) {
          data.imgList = await this.saveImgs(data.imgList)
          this.reservoirList.push(data)
          this.checkListObj.dataProject.reservoirList = this.reservoirList
        }
          break;
        default:
          console.error("Type does not exist!")
      }
    },
    async updateProposalinFirebase(objSave) {
      await WaterScanService.updateProposal(objSave, this.idProposal)
      .then(() => this.validFormProp(true,1))
      .catch((err) => {
        console.error("CheckList - Erro ao ATUALIZAR proposta!" + err);
      });
    },
    async resquetEvent(pag,pullStep = false) {
      if (pag != 1 || pullStep) {
        if (this.$refs.form && !this.$refs.form.validate()) {
          this.validFormProp(false,1)
          return;
        }

        if(!this.responseConsumptionForm.length){
          this.validFormProp(false,1)
          return;
        }
        this.checkListObj.solutionList = this.responseConsumptionForm;
        this.solutionList = this.responseConsumptionForm;
        this.$emit("update:overley", true);
        await this.updateProposalinFirebase(this.checkListObj);
        if(pullStep)this.closeProposed();
      }
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    rulesCEP() {
      return Rules.cep();
    },
    rulesLogradouro() {
      return Rules.string(false, null, 128);
    },
    rulesCity() {
      return Rules.string(false, null, 64);
    },
    rulesDistrict() {
      return Rules.string(false, null, 64);
    },
    rulesAdditionalAddress() {
      return Rules.string(false, null, 64);
    },
    rulesHouseNumber() {
      return Rules.string(false, null, 16);
    },
  },
  watch: {
    requestSave() {
      this.resquetEvent(true);
    },
  },
  async created(){
    if(this.viewOrEdit === 'view'){
      await WaterScanService.getProposalById(this.idProposal)
      .then((response) => {
          if("dataProject" in response){
            this.checkListObj.dataProject = response.dataProject

            this.reservoirList = this.checkListObj.dataProject.reservoirList || []
            this.bombList = this.checkListObj.dataProject.bombList || []
            this.hydrometerList = this.checkListObj.dataProject.hydrometerList || []
          }else{
            this.checkListObj.dataProject = { type: null }
          }
          if("solutionList" in response){
            this.checkListObj.solutionList = response.solutionList
            this.solutionList = response.solutionList
          }    
        })
        .catch((err) => {
          alert("Erro ao buscar proposta!");
          console.error(err);
        });
    }
  },
};
</script>