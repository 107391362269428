<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <v-overlay absolute style="opacity: 0.6" :value="isLoadingCNPJ">
      <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" v-if="getCurrentUserRank === 'Super Admin'">
        <v-autocomplete 
          v-model="franchiseeSelected" :items="listFranchisees" item-text="name" item-value="id"
          label="Franqueado" dense outlined hide-details 
          :rules="rulesRequired"
          />
      </v-col>
      <v-col cols="12">
        <h3>Informações do local:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        <v-radio-group v-model="enrollObj.localeData.idType" :rules="rulesRequired" @change="selectRadiofCpfCnpj()"
          style="margin: 0" required>
          <v-radio :key="1" :label="'CPF'" value="CPF"></v-radio>
          <v-radio :key="2" :label="'CNPJ'" value="CNPJ"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col md="8" lg="8" xl="8" sm="8" cols="12">
        <v-text-field v-if="enrollObj.localeData.idType == 'CPF'" label="CPF" outlined
          v-model="enrollObj.localeData.idNumber" v-mask="'###.###.###-##'" required ref="refCpf"
          :rules="rulesIdNumber"></v-text-field>
        <v-text-field v-else-if="enrollObj.localeData.idType == 'CNPJ'" label="CNPJ" outlined
          v-model="enrollObj.localeData.idNumber" v-mask="'##.###.###/####-##'" required @blur="consultaCnpj"
          ref="refCnpj" :rules="rulesIdNumber"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field id="teste" outlined label="Matrícula" v-model="registration"
          counter="64">
        </v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field id="teste" outlined label="Nome do Local" prepend-inner-icon="person" v-model="enrollObj.name"
          :rules="rulesName" counter="64" required>
        </v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field outlined prepend-inner-icon="alternate_email" v-model="enrollObj.email" color="primary"
          label="E-mail" required :rules="rulesEmail" counter="64"></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field label="Telefone / Celular" outlined v-mask="'(XX) XXXXXXXXX'" v-model="enrollObj.localeData.phone"
          :rules="rulesPhone" required></v-text-field>
      </v-col>
    </v-row>
    <!-- Inicio Endereço -->
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <h3>Endereço:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          outlined
          label="CEP"
          v-model="enrollObj.localeData.address.cep"
          @input="debouncedGetCep"
          v-mask="'XX.XXX-XXX'"
          :rules="rulesCEP"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"  md="7">
        <v-text-field
          outlined
          label="Logradouro"
          v-model="enrollObj.localeData.address.logradouro"
          :rules="rulesLogradouro"
          required
          counter="128"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"  md="2">
        <v-text-field
          outlined
          label="Número"
          type="number"
          v-model="enrollObj.localeData.address.numero"
          :rules="rulesHouseNumber"
          counter="16"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" >
        <v-text-field
          outlined
          label="Complemento"
          v-model="enrollObj.localeData.address.complemento"
          :rules="rulesAdditionalAddress"
          counter="64"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" >
        <v-text-field
          outlined
          label="Bairro"
          v-model="enrollObj.localeData.address.bairro"
          :value="enrollObj.localeData.address.bairro"
          :rules="rulesDistrict"
          counter="64"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" >
        <v-text-field
          outlined
          label="Cidade"
          v-model="enrollObj.localeData.address.cidade"
          :rules="rulesCity"
          required
          counter="64"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select outlined label="UF" :items="uf" v-model="enrollObj.localeData.address.uf">
        </v-select>
      </v-col>
    </v-row>


    <!-- Fim Endereço -->
    <v-divider></v-divider>
    <!-- Inicio Informações do responsável -->
    <v-row>
      <v-col cols="12">
        <h3>Informações do responsável:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field outlined label="Nome do responsável" prepend-inner-icon="person"
          v-model="enrollObj.responsible.name" :rules="rulesName" counter="64">
        </v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field outlined prepend-inner-icon="alternate_email" v-model="enrollObj.responsible.email"
          color="primary" label="E-mail" counter="64"></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field label="CPF" outlined v-mask="'###.###.###-##'" v-model="enrollObj.responsible.idNumber"
          ref="refCpf"></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field label="Telefone" outlined v-mask="'(XX) XXXXX-XXXX'" v-model="enrollObj.responsible.phone"
          :rules="rulesPhone"></v-text-field>
      </v-col>
    </v-row>
    <!-- Fim Informações do responsável -->
  </v-form>
</template>
<script>
import Rules from "@/utils/Rules";
import ObjectValidator from "../../../scripts/ObjectValidator";
import { jsonp } from "vue-jsonp";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import debounce from 'lodash/debounce';
import { mapGetters } from "vuex";
import UsersService from "@/services/firebase/Users/UsersService";

export default {
  props: {
    idProposal: {
      default: ""
    },
    requestSave: {
      default: null,
    },
    viewOrEdit: {
      default: "create"
    },
    validFormProp: {
      default: () => { },
    },
    closeProposed: {
      default: () => { },
    },
  },
  name: "locationInformation",
  data: function () {
    return {
      uf: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
      registration: '',
      responseCheckRegister: null,
      validForm: true,
      isLoadingCNPJ: false,
      lalala: null,
      valid: false,
      isPwsVisible: false,
      requestAdress: false,
      enrollObj: ObjectValidator.create("enroll"),
      proposalObject: {},
      savedProposal: false,
      franchiseeSelected: null,
      listFranchisees: [],
    };
  },
  methods: {
    clearAdress() {
      this.enrollObj.localeData.address.bairro = null;
      this.enrollObj.localeData.address.cidade = null;
      this.enrollObj.localeData.address.complemento = null;
      this.enrollObj.localeData.address.logradouro = null;
      this.enrollObj.localeData.address.numero = null;
      this.enrollObj.localeData.address.uf = null;
    },
    getCep() {
      if (this.enrollObj.localeData.address.logradouro) return; 
      let cep = this.enrollObj.localeData.address.cep;
      cep = cep.replace(/\D/g, "");

      if (cep != "" && cep.length == 8) {
        // this.isLoadingAdress = true
        this.clearAdress();
        let url = "https://viacep.com.br/ws/" + cep + "/json/";

        fetch(url)
          .then((response) => response.json())
          .then((responseJson) => {
            this.enrollObj.localeData.address.bairro = responseJson.bairro;
            this.enrollObj.localeData.address.cidade = responseJson.localidade;
            this.enrollObj.localeData.address.complemento = responseJson.complemento;
            this.enrollObj.localeData.address.logradouro = responseJson.logradouro;
            this.enrollObj.localeData.address.uf = responseJson.uf;
            // this.isLoadingAdress = false
          })
          .catch((err) => {
            // this.isLoadingAdress = false
            console.error("Erro", err)});
          
      } else {
        this.clearAdress();
      }
    },
    clearObject() {
      this.enrollObj = {
        manager: null,
        localeData: {
          address: {
            bairro: null,
            cep: null,
            cidade: null,
            complemento: null,
            logradouro: null,
            numero: null,
            uf: null,
          },
          idNumber: null,
          phone: null,
          idType: this.enrollObj.localeData.idType,
        },
        responsible: {
          idNumber: null,
          phone: null,
          idType: "CPF",
          email: null,
          name: null,
        },
      };
    },
    selectRadiofCpfCnpj() {
      this.enrollObj.localeData.idNumber = null;
      this.clearObject();
    },
    consultaCnpj() {
      if (this.enrollObj.localeData.idNumber !== null) {
        if (this.enrollObj.localeData.idNumber.length == 18) {
          this.isLoadingCNPJ = true;
          jsonp(
            "https://receitaws.com.br/v1/cnpj/" +
            this.enrollObj.localeData.idNumber
              .split(".")
              .join("")
              .split("/")
              .join("")
              .split("-")
              .join("")
          )
            .then((response) => {
              this.enrollObj.name = response.fantasia;
              this.enrollObj.localeData.phone = response.telefone;
              this.enrollObj.email = response.email;
              this.enrollObj.localeData["address"] = {
                logradouro: response.logradouro,
                numero: response.numero,
                complemento: response.complemento,
                bairro: response.bairro,
                cidade: response.municipio,
                cep: response.cep,
                uf: response.uf,
              };
              this.usersKey++;
              this.isLoadingCNPJ = false;
            })
            .catch((err) => {
              this.isLoadingCNPJ = false;
              console.error(err);
            });
        }
      }
    },
    async createProposalinFirebase(objSave) {
      if (!this.responseCheckRegister) {
        await WaterScanService.createProposal(objSave, this.idProposal)
          .then((reponse) => {
            this.proposalObject = reponse;
            this.savedProposal = true
            this.validFormProp(true, 0)
          })
          .catch((err) => {
            alert("Erro ao CRIAR proposta!");
          });
      } else {
        await WaterScanService.updateProposal(objSave, this.idProposal)
          .then(() => {
            this.validFormProp(true, 0)
          })
          .catch((err) => {
            console.error("LocationInformation - Erro ao ATUALIZAR proposta!" + err);
          });
      }
    },
    async resquetEvent(pag, pullStep = false) {

      if (pag != 0 || pullStep) {
        if (this.$refs.form && !this.$refs.form.validate()) {
          this.validFormProp(false, 0)
          return false;
        }

        let objSave = {
          id_franchisee: this.franchiseeSelected,
          locationInformation: this.enrollObj,
          registration: this.registration,
        };

        if (!this.savedProposal) {
          this.$emit("update:overley", true);
          await this.createProposalinFirebase(objSave);
        }
        if (pullStep) this.closeProposed();
      }
    },
    debouncedGetCep: debounce(function () {
      this.getCep();
    }, 300),
  },
  computed: {
    ...mapGetters(["getCurrentUserRank", "getCurrentUser", "getUnitByIDs", "getAssociados"]),
    rulesIdNumber() {
      return this.enrollObj["localeData"].idType === "CPF"
        ? Rules.cpf()
        : Rules.cnpj();
    },
    rulesIdNumberCpf() {
      return Rules.cpf();
    },
    rulesRequired() {
      return Rules.required();
    },
    rulesPhone() {
      return Rules.phone();
    },
    rulesEmail() {
      return Rules.email(true, 64);
    },
    rulesName() {
      return Rules.string(true, 1, 64);
    },
    rulesCEP() {
      return Rules.cep(true);
    },
    rulesLogradouro() {
      return Rules.string(true, null, 128);
    },
    rulesCity() {
      return Rules.string(true, null, 64);
    },
    rulesDistrict() {
      return Rules.string(true, null, 64);
    },
    rulesAdditionalAddress() {
      return Rules.string(false, null, 64);
    },
    rulesHouseNumber() {
      return Rules.string(true, null, 16);
    },
  },
  async created() {
    if (this.getCurrentUserRank === "Franqueado")this.franchiseeSelected = this.getCurrentUser.uid 
    else if (this.getCurrentUserRank === "Super Admin"){
      UsersService.getUsersByRank(2)
      .then((res) => {
        console.log("res",res)
        this.listFranchisees = res
      }).catch(() => { })
    }
    if (this.viewOrEdit === 'view') {
      this.$emit("update:overley", true);
      await WaterScanService.getProposalById(this.idProposal).then((response) => {
        this.responseCheckRegister = response;
        this.$emit("update:overley", false);
      })
        .catch((err) => {
          alert("Erro ao consultar proposta!");
          console.error(err);
        });

      if (this.responseCheckRegister) {
        this.franchiseeSelected = this.responseCheckRegister.id_franchisee ? this.responseCheckRegister.id_franchisee : null
        this.enrollObj = this.responseCheckRegister.locationInformation
        this.registration = this.responseCheckRegister.registration
      }
    }
  },
};
</script>