import { mapActions, mapGetters } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

import ProjectsService from "@/services/server/ProjectsService.js";
import LeadsService from "@/services/server/LeadsService.js";
import UsersService from "@/services/server/UsersService";

import Propecting from "@/components/Projects/Prospecting/Prospecting.vue"

export default {
	name: "Detalhes do Projeto",

	components: {
        Propecting,
	},

	data: () => ({
		is_loading: true,
        is_created: false,
        has_error: false,
        project: {
            id_lead: null,
            id_doc: null,
        },
        options: [
            {
                icon: 'mdi-magnify',
                name: 'Prospecção',
                id_name: 'id_lead',
                open: function () {
                    
                }
            },
            {
                icon: 'mdi-water',
                name: 'Waterscan',
                id_name: 'id_waterscan',
                open: function () {
                    
                }
            },
            {
                icon: 'mdi-check-bold',
                name: 'Aprovação',
                id_name: '',
                open: function () {
                    
                }
            },
            {
                icon: 'mdi-file-sign',
                name: 'Fechamento',
                id_name: '',
                open: function () {
                    
                }
            },
            {
                icon: 'mdi-file-document',
                name: 'Contratos',
                id_name: '',
                open: function () {
                    
                }
            },
            {
                icon: 'mdi-cube-outline',
                name: 'Materiais',
                id_name: '',
                open: function () {
                    
                }
            },
            {
                icon: 'mdi-wrench-cog',
                name: 'Instalação',
                id_name: '',
                open: function () {
                    
                }
            },
        ],
	}),

	computed: {
		
	},

	methods: {
		...mapActions([
			"publishChannel",
		]),
        checkId(id_name) {
            return this.project.hasOwnProperty(id_name)
        },
	},
	
	watch: {
		
	},

	async created() {
		let [status, project] = await ProjectsService.getProjectById(this.$route.params.id)
        if (!status) {
            this.has_error = true
        } else {
            let created_at = new Date(project.created_at._seconds * 1000)
            this.project = {
                ...project,
                created_at,
                created_at_formatted: Utils.formatDate(created_at, '<dd>/<MM>/<YYYY>')
            }
            // Prospecção
            this.options[0].open = () => {
                this.publishChannel({
                    channel: 'projects',
                    event: 'leads:open',
                    value: [
                        this.project.id_lead,
                        this.project.id_doc,
                    ],
                })
            }
        }
        this.is_created = true
    },
};