

import { storage } from "@/firebase"
import Compressor from 'compressorjs';

async function copyFile(sourceFilePath, destinationFilePath) {
    try {
      const sourceRef = storage.ref().child(sourceFilePath);
      const destinationRef = storage.ref().child(destinationFilePath);
  
      // Use o método copyTo para copiar o arquivo internamente
      await sourceRef.copyTo(destinationRef);
  
      console.log(`Arquivo copiado de ${sourceFilePath} para ${destinationFilePath}`);
    } catch (error) {
      console.error('Erro ao copiar o arquivo:', error);
    }
  }

const compressorFile = async (file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.4,
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err)
                console.error(err.message);
            },
        });
    })
}
const uploadImg = async (register, file) => {
    const fileCompress = await compressorFile(file)
    const imgNameSaved = new Date().getTime() +"_"+file.name
    const pathSaved = `water_fix_imgs/${register}/${imgNameSaved}`
    const storageWS_REF = storage.ref(pathSaved)
    var task = storageWS_REF.put(fileCompress);
    await new Promise((resolve, reject) => {
        task.on('state_changed',
            function progress(snapshot) {
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            },
            function error(err) {
                reject(err)
            },
            function complete() {
                var downloadURL = task.snapshot.downloadURL;
                resolve("Download Complet")
            }
        );
    })
    return pathSaved
}
const uploadImageByFilepath = async (pathSaved, file) => {
    const fileCompress = await compressorFile(file)
    const storageWS_REF = storage.ref(pathSaved)
    var task = storageWS_REF.put(fileCompress);
    await new Promise((resolve, reject) => {
        task.on('state_changed',
            function progress(snapshot) {
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            },
            function error(err) {
                reject(err)
            },
            function complete() {
                var downloadURL = task.snapshot.downloadURL;
                resolve("Download Complet")
            }
        );
    })
    return pathSaved
}
const getFile = async (path) => {
    const ref = await storage.ref(path)
    return {
        name: ref.name,
        url: await ref.getDownloadURL() 
    }
}
const downloadFile = async (path) => {
    return await storage.ref(path).getDownloadURL() 
}
const deleteFile = async (path) => {
    return await storage.ref(path).delete()
}
const downloadImg = async (path) => {
    return downloadFile(path)
}
const deleteImg = async (path) => {
    return deleteFile(path)
}

const uploadPDF = async (filepath, file) => {
    if (file.type !== "application/pdf") {
      throw new Error("File is not a PDF");
    }
    const storageRef = storage.ref(filepath);
    const task = storageRef.put(file);
    await new Promise((resolve, reject) => {
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        function error(err) {
          reject(err);
        },
        function complete() {
          resolve("Upload Complete");
        }
      );
    });
    return filepath;
};

const downloadPDF = async (path) => {
    return downloadFile(path)
}

const deletePDF = async (path) => {
    return deleteFile(path)
}

export default {
    copyFile,
    getFile,
    uploadImg,
    deleteImg,
    downloadImg,
    uploadPDF,
    downloadPDF,
    deletePDF,
    uploadImageByFilepath,
}