<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<v-card>
					<v-card-title style="justify-content: space-between;">
						<h3>Projetos</h3>
						<div>
							<v-btn 
								color="primary" 
								text
								@click="() => openDialog()"
								:loading="is_loading"
								:disabled="is_loading"
							>
								<v-icon>add</v-icon>
								Novo Projeto
							</v-btn>
						</div>
					</v-card-title>
					<v-card-text>
						<v-row style="justify-content: space-between;">
							<v-col cols="8">
								<v-text-field s
									class="px-4" 
									prepend-icon="search" 
									label="Busca"
									v-model="searchField"
									:loading="is_loading"
									:disabled="is_loading"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-select 
									hint="Buscar nessa coluna" 
									persistent-hint 
									v-model="searchOptionSelected" 
									:items="searchOptions" 
									return-object 
									single-line
									:loading="is_loading"
									:disabled="is_loading"
								></v-select>
							</v-col>
						</v-row>
						<v-row style="margin: 0;">
							<v-col cols="6">
								<v-select 
									hint="Franqueado" 
									persistent-hint 
									v-model="franchisee_selected" 
									:items="franchisee_list" 
									item-text="name"
									return-object
									single-line
									:loading="is_loading"
									:disabled="is_loading"
								></v-select>
							</v-col>
							<v-col cols="6">
								<v-select 
									hint="Etapa do Projeto" 
									persistent-hint 
									v-model="project_steps_selected" 
									:items="project_steps_list" 
									item-text="name"
									return-object
									single-line
									:loading="is_loading"
									:disabled="is_loading"
								></v-select>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text>
						<v-skeleton-loader
							v-if="!is_created"
							type="table-heading, table-tbody"
						></v-skeleton-loader>
						<v-data-table
							v-if="is_created"
							:headers="headers"
							:items="getItems"
							item-key="name"
							class="elevation-1"
						>
							<template v-slot:body="{ items }">
								<tbody>
								<tr v-for="(item, i) in items" :key="i">
			
									<td>
										{{ item.project_name }}
									</td>
									<td>
										{{ item.lead_name }}
									</td>
									<td>
										{{ item.lead_document }}
									</td>
									<td>
										{{ item.created_at_formatted }}
									</td>
									<td>
										<v-btn 
											@click="() => navigateProject(item)" 
											v-ripple 
											style="margin-right: 5px;"
											:loading="is_loading"
											:disabled="is_loading"
										>
											<v-icon small color="primary"> mdi-eye </v-icon>
										</v-btn>
										
										<v-btn 
											@click="() => {}" 
											v-ripple
											:loading="is_loading"
											:disabled="is_loading"
										>
											<v-icon small color="primary"> mdi-delete </v-icon>
										</v-btn>
									</td>
								</tr>
								</tbody>
						  	</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<template>
			<v-row justify="center">
				<v-dialog
					v-model="dialog"
					persistent
					max-width="450"
				>
					<v-form 
						ref="newProjectForm"
						v-model="isValidForm"
						lazy-validation
					>
						<v-card>
							<v-card-title class="text-h5">
								<h5>Adicionar um Novo Projeto</h5>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col>
										<v-text-field
											v-model="form.project_name"
											label="Nome do Projeto"
											:counter="64"
											required
											:rules="ruleString(true, 64)"
											:loading="is_loading"
											:disabled="is_loading"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="4">
										<v-select
											v-model="type_lead_document_selected"
											:items="type_lead_documents"
											label="Tipo do Documento"
											:loading="is_loading"
											:disabled="is_loading"
										></v-select>
									</v-col>
									<v-col cols="8">
										<v-text-field
											v-model="form.lead_document"
											:label="`Digite ${['CPF', 'CNPJ'].includes(type_lead_document_selected) ? `o` : `a `} ${type_lead_document_selected} do Cliente`"
											v-mask="type_lead_documents_masks[type_lead_document_selected]"
											:rules="type_lead_document_selected == 'CPF' ? ruleCPF(true) : type_lead_document_selected == 'CNPJ' ? ruleCNPJ(true) : ruleRequired()"
											:loading="is_loading"
											:disabled="is_loading"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-select 
											label="Franqueado"
											persistent-hint 
											v-model="form.id_franchisee" 
											:items="project_franchisee_list" 
											item-text="name"
											return-object
											single-line
											:loading="is_loading"
											:disabled="is_loading"
											:rules="ruleRequired()"
										></v-select>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="green darken-1"
									text
									@click="() => closeDialog()"
								>
									Cancelar
								</v-btn>
								<v-btn
									color="green darken-1"
									text
									@click="() => createProject()"
								>
									Criar
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
			  </v-dialog>
			</v-row>
		</template>

		<Propecting />
	</v-container>
</template>
  
<script>
import ProjectsScript from "./ProjectsScript.js";
export default ProjectsScript;
</script>
  
<style scoped>

</style>
  