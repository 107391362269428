import apiService from "./BaseApi";
import store from '@/store/index'
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"

const BASE_BODY = {
    currentUserId: store.state.currentUser?.uid,
}

const ProjectsService = {
    getProjects: async () => {
        try {
            let response = await apiService.post(
                "projects/list",
                BASE_BODY
            )
            showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.log("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    getProjectById: async (id_project) => {
        try {
            let response = await apiService.get(
                `projects/${id_project}`,
                BASE_BODY
            )
            showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.log("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    createProject: async (project) => {
        try {
            let response = await apiService.post(
                "projects", {
                    ...BASE_BODY,
                    ...project,
                }
            )
            showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.log("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
};

export default ProjectsService;
